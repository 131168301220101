






















import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ICategory } from '@/typings/interface/ICategory';
import { MCategory } from '@/models/MCategory';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';

export default (Vue as VueConstructor).extend({
  name: 'CategoryEdit',

  components: {
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentCategory: {
      get(): MCategory {
        return MCategory.find(this.ID);
      },

      async set(category: MCategory) {
        this.dirty = true;

        await MCategory.update({
          where: this.ID,

          data: category,
        });
      },
    },
  },

  methods: {
    updateCategoryName(Name: string) {
      this.currentCategory = {
        ...this.currentCategory,
        CategoryName: Name,
      };
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('category.alert.delete', {
          categoryName: this.currentCategory.CategoryName,
        }),
      );

      if (confirmation.value) {
        MCategory._delete(this.currentCategory.Id);

        this.closeModal();
      }
    },

    sync(): void {
      MCategory._update(this.currentCategory);
    },
  },
});
