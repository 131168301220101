























import BaseGridList from '@/components/base/grid/BaseGridList.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { categoryGridActionList } from '@/utils/gridActions/categoryGridActionList';
import {
  categoryRouteEdit,
  categoryRouteNew,
  categoryRouteShow,
} from '@/views/definitions/category/routes';
import { MCategory } from '@/models/MCategory';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CategoryShow',

  components: {
    BaseGridList,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      gridActionList: categoryGridActionList,

      gridColumnDefinition: [
        {
          headerName: this.$tc('category.property.name'),
          field: 'name',
        },
      ],
    };
  },

  computed: {
    categoryList(): Array<MCategory> {
      return MCategory.all();
    },

    gridRowData(): Array<any> {
      return this.categoryList.map(
        category => category.asListData,
      );
    },
  },

  methods: {
    add(): void {
      this.$router.push(categoryRouteNew(this.$i18n.locale));
    },

    closeModal(): void {
      this.$router.push(categoryRouteShow(this.$i18n.locale));
    },

    edit(categoryID: string): void {
      this.$router.push({
        name: categoryRouteEdit(this.$i18n.locale).name,

        params: {
          ID: categoryID,
        },
      });
    },

    async remove(categoryID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('category.alert.delete', {
          categoryName: MCategory.find(categoryID).CategoryName,
        }),
      );

      if (confirmation.value) {
        MCategory._delete(categoryID);
      }
    },
  },
});
