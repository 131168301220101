























import BaseGridList from '@/components/base/grid/BaseGridList.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { MTax } from '@/models/MTax';
import { taxGridActionList } from '@/utils/gridActions/taxGridActionList';
import {
  taxRouteEdit,
  taxRouteNew,
  taxRouteShow,
} from '@/views/definitions/tax/routes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'TaxShow',

  components: {
    BaseGridList,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      gridActionList: taxGridActionList,

      gridColumnDefinition: [
        {
          headerName: this.$tc('tax.property.name'),
          field: 'name',
        },
      ],
    };
  },

  computed: {
    taxList(): Array<MTax> {
      return MTax.all();
    },

    gridRowData(): Array<any> {
      return this.taxList.map(
        tax => tax.asListData,
      );
    },
  },

  methods: {
    add(): void {
      this.$router.push(taxRouteNew(this.$i18n.locale));
    },

    closeModal(): void {
      this.$router.push(taxRouteShow(this.$i18n.locale));
    },

    edit(taxID: string): void {
      this.$router.push({
        name: taxRouteEdit(this.$i18n.locale).name,

        params: {
          ID: taxID,
        },
      });
    },

    async remove(taxID: string): Promise<any> {
      const tax = MTax.find(taxID);

      if (!tax.Disabled) {
        const confirmation = await this.confirmAlertDelete(
          this.$tc('tax.alert.delete', {
            taxName: tax.Name,
          }),
        );

        if (confirmation.value) {
          MTax._delete(taxID);
        }
      }
    },
  },
});
