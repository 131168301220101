import { render, staticRenderFns } from "./TaxShow.vue?vue&type=template&id=9d43c424&"
import script from "./TaxShow.vue?vue&type=script&lang=ts&"
export * from "./TaxShow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports