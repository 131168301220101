

































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ITax } from '@/typings/interface/ITax';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MTax } from '@/models/MTax';

export default (Vue as VueConstructor).extend({
  name: 'TaxEdit',

  components: {
    BaseInputField,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentTax: {
      get(): MTax {
        return MTax.find(this.ID);
      },

      async set(tax: MTax) {
        this.dirty = true;

        await MTax.update({
          where: this.ID,

          data: tax,
        });
      },
    },
  },

  methods: {
    updateTaxName(Name: string) {
      this.currentTax = {
        ...this.currentTax,
        Name: Name,
      };
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('tax.alert.delete', {
          taxName: this.currentTax.Name,
        }),
      );

      if (confirmation.value) {
        MTax._delete(this.currentTax.Id);

        this.closeModal();
      }
    },

    sync(): void {
      if (!this.currentTax.Disabled) {
        MTax._update(this.currentTax);
      }
    },

    updateTax(value) {
      if (this.currentTax.Percent !== value) {
        this.currentTax = {
          ...this.currentTax,
          Percent: value,
        };
      }
    },
  },
});
