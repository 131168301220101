import { ACTION, actionMap } from '@/utils/gridActions/actionMap';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';

const taxGridActionList: Array<IBaseGridActionListItem> = [
  {
    ...actionMap.get(ACTION.ADD),
    tooltip: 'tax.action.add',
  },
  {
    ...actionMap.get(ACTION.CONTEXT_MENU),

    childActionList: [
      actionMap.get(ACTION.EDIT),

      actionMap.get(ACTION.DELETE),
    ],
  },
];

export { taxGridActionList };
